// Moment.jsの代わりにdayjsを使用するためのDatePicker
// https://ant.design/docs/react/replace-moment
// 専用のWebpack Plugin(https://ant.design/docs/react/replace-moment#antd-dayjs-webpack-plugin)もあるが、
// 2021/5/6時点で正しく動作しないため独自で作成している

import generatePicker from 'antd/lib/date-picker/generatePicker'
import { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'

export const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)
