import { Button, Divider, Space } from 'antd'
import locale from 'antd/lib/date-picker/locale/ja_JP'
import dayjs from 'dayjs'
import React, { FC } from 'react'

import { addDate, subtractDate, YearMonth } from '../../../utils/date'
import { DatePicker } from '../../DatePicker'

type Props = {
  defaultValue: YearMonth
  onChange: (value: YearMonth) => void
  start?: Date
  end?: Date
}

export const MonthPicker: FC<Props> = ({
  defaultValue,
  onChange,
  start,
  end,
}) => {
  const value = new Date(defaultValue.year, defaultValue.month - 1)
  const prev = subtractDate(value, 1, 'month')
  const next = addDate(value, 1, 'month')

  return (
    <Space direction="vertical" align="center">
      <DatePicker
        picker="month"
        defaultValue={dayjs(value)}
        disabledDate={(date) =>
          (start ? date.isBefore(start) : false) ||
          (end ? date.isAfter(end) : false)
        }
        onChange={(date) =>
          date && onChange({ year: date.year(), month: date.month() + 1 })
        }
        locale={locale}
        format="YYYY年MM月"
        allowClear={false}
      />
      <Space split={<Divider type="vertical" />}>
        <Button
          size="small"
          type="link"
          onClick={() =>
            onChange({ year: prev.getFullYear(), month: prev.getMonth() + 1 })
          }
          disabled={start && prev < start}
        >
          ‹ 前月
        </Button>
        <Button
          size="small"
          type="link"
          onClick={() =>
            onChange({ year: next.getFullYear(), month: next.getMonth() + 1 })
          }
          disabled={end && next > end}
        >
          翌月 ›
        </Button>
      </Space>
    </Space>
  )
}
