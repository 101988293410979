import { useRouter } from 'next/router'
import React from 'react'

import { ReportsUsagePage } from '../../../components/pages/ReportsUsagePage'
import { isValidDate } from '../../../utils/date'

const ReportsUsage: React.FC = () => {
  const router = useRouter()
  const { year, month } = router.query

  if (
    typeof year !== 'string' ||
    typeof month !== 'string' ||
    !isValidDate(new Date(parseInt(year, 10), parseInt(month, 10) - 1))
  ) {
    const now = new Date()
    router.replace(
      `/reports/usage?year=${now.getFullYear()}&month=${now.getMonth() + 1}`
    )
    return null
  }

  return (
    <ReportsUsagePage
      yearMonth={{ year: parseInt(year, 10), month: parseInt(month, 10) }}
    />
  )
}

export default ReportsUsage
