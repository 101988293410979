import 'dayjs/locale/ja'

import dayjs, { ManipulateType } from 'dayjs'

export type DateRange = {
  from: Date
  to: Date
}

// formatを省略するとISO8601となる (dayjsのデフォルト。ミリ秒は無し)
export const formatDate = (
  date: Date | string,
  format?:
    | 'YYYY年MM月DD日(dd)'
    | 'YYYY年MM月DD日(dd) HH:mm:ss'
    | 'YYYYMMDDHHmmss'
) => dayjs(date).locale('ja').format(format)

export type YearMonth = {
  year: number
  month: number
}

export const newDate = (value: string, format?: string) =>
  dayjs(value, format).toDate()

// dayjsのisValidだと存在しない日付もvalidになってしまうため使用しない
export const isValidDate = (value: string | Date) =>
  !Number.isNaN(new Date(value).getTime())

// encodeURIComponentメソッドでは「:」もエンコードされてしまうため+のみ置き換える
export const urlEncodeISO8601 = (value: string) => value.replace(/\+/g, '%2b')

export const addDate = (date: Date, value: number, unit?: ManipulateType) =>
  dayjs(date).add(value, unit).toDate()

export const subtractDate = (
  date: Date,
  value: number,
  unit?: ManipulateType
) => dayjs(date).subtract(value, unit).toDate()

export const getDaysOfMonth = (yearMonth: YearMonth) => {
  const { year, month } = yearMonth
  const firstOfMonth = dayjs(`${year}-${month}-1T00:00:00`)
  const endOfMonth = dayjs(firstOfMonth).endOf('month')
  return generateNumberSequence(endOfMonth.daysInMonth(), 1)
}

const generateNumberSequence = (length: number, start: number = 0) =>
  [...Array(length)].map((_, i) => i + start)
