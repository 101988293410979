import { Result } from 'antd'
import React from 'react'

export const Error500Template: React.FC = () => (
  <Result
    status="500"
    title="ページが表示できません"
    subTitle="ご不便をおかけし申し訳ございません。不明なエラーが発生いたしました。"
  />
)
